import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import EnglishIndex from './en/EnglishIndex';
import SpanishIndex from './es/SpanishIndex';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/es">
            <SpanishIndex />
          </Route>
          <Route path="/en">
            <EnglishIndex />
          </Route>
          <Route path="/">
            <SpanishIndex />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
