import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function EnglishIndex() {

  function handleClick(e) {
    e.preventDefault();
  } 

  return (
    <React.Fragment>
      { /* Start Prealoader Area */ }
        <div className="preloader-area">
            <div className="sk-circle">
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
            </div>
        </div>
        { /* End Prealoader Area */ }

        { /* Start Navbar Area */ }
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <a className="navbar-brand pirulen-font" href="/"><span>A</span>glaya</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
    
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item"><a className="nav-link" href="#home">Home</a></li>
                        <li className="nav-item"><a className="nav-link" href="#about">About Us</a></li>
                        <li className="nav-item"><a className="nav-link" href="#services">Our Services</a></li>
                        <li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>
                        <li className="nav-item"><a className="nav-link" href="#contact">Contact Us</a></li>
                        <li className="nav-item">
                        <a className="nav-link" href="/" title="Eglish Version">
                            <img src="./../img/nicaragua.png" alt="english"/>
                          </a>
                        </li>
                        <li className="nav-item"><a className="nav-link" href="#" data-toggle="modal" data-target="#myModal"><span></span></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        { /* End Navbar Area */ }

        { /* Right Side Modal */ }
        <div className="modal right fade" id="myModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="logo pirulen-font"><a href="index.html">Aglaya <span>Software</span></a></div>
                        <p>Efficiency, control, resources optimization. We offer tools that allow you to have a privileged position in the market, we help you to generate value for your company by optimizing your own resources with the use of technology.</p>
                        <ul className="modal-contact-info">
                            { /* <li><i className="icofont-google-map"></i> <b>Los Robles</b>Managua, Nicaragua</li> */ }
                            <li><i className="icofont-ui-call"></i> <b>+(505) 5704 8835</b>Nicaragua</li>
                            <li><i className="icofont-ui-call"></i> <b>+34 (640) 85 84 87</b>Spain</li>
                            <li><i className="icofont-envelope"></i> <b>info@aglayasoftware.com</b>Contact us!</li>
                        </ul>

                        { /* <ul className="social-links">
                            <li><a href="javascript: void(0)"><i className="icofont-facebook"></i></a></li>
                            <li><a href="javascript: void(0)"><i className="icofont-twitter"></i></a></li>
                            <li><a href="javascript: void(0)"><i className="icofont-instagram"></i></a></li>
                            <li><a href="javascript: void(0)"><i className="icofont-linkedin"></i></a></li>
                            <li><a href="javascript: void(0)"><i className="icofont-pinterest"></i></a></li>
                        </ul> */ }
                    </div>
                </div>
            </div>
        </div>
		{ /* End Right Side Modal */ }

        { /* Start Main Banner */ }
        <div id="home" className="main-banner item-bg-two">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="hero-content">
                            <h5>Coding your dreams!</h5>
                            <h1 className="pirulen-font"><span>Aglaya</span> Software</h1>
                            <p>Professional developers to create your ideas in great systems.</p>                            
                            { /* <a href="#contact" className="btn btn-primary">Cont&aacute;ctanos</a> */ }
                            { /* <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" className="video-btn"><i className="icon icon-Play"></i> Watch Video</a> */ }
                        </div>
                    </div>
                </div>
            </div>

            <div className="down_arrow">
                <a href="#about" className="scroll_down"></a>
            </div>

            <div className="animation-box1"><img src="../img/glasses.png" alt="service"/></div>            
            <div className="animation-box2"><img src="../img/cloud.png" alt="service"/></div>
            <div className="animation-box3"><img src="../img/code.png" alt="service"/></div>
            <div className="animation-box6"><img src="../img/6.png" alt="service"/></div>

            <div className="creative-box1"></div>
        </div>
        { /* End Main Banner */ }

        { /* Start Welcome Area */ }
        <section id="" className="welcome-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2><b>Aglaya Software</b></h2>
                    <p>Efficiency, control, resources optimization. We offer tools that allow you to have a privileged position in the market, we help you to generate value for your company by optimizing your own resources with the use of technology.</p>
                </div>

                { /* <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-box">
                            <i className="icon icon-Star"></i>
                            <h3>Creatividad</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-box">
                            <i className="icon icon-Starship"></i>
                            <h3>Innovaci&oacute;n</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-box">
                            <i className="icon icon-ChartUp"></i>
                            <h3>Fast Support</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </div>
                </div> */ }
            </div>
        </section>
        { /* End Welcome Area */ }

        { /* Start About Area */ }
        <section id="about" className="about-area ptb-100 bg-f9f9f9">
            <div className="container">
                <div className="section-title">
                    <h2><b className="blueText">About</b> Us</h2>
                </div>

                <div className="row">
                    <div className="m-b-20 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-10 offset-1">
                        <img data-src="../img/banner-pc-en.png" alt="Aglaya Software - Software Development" style={{ width: '100%' }} className="lazyload"/>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="about-slides">
                                <div className="col-lg-12">
                                    <div className="about-title">
                                        <h3><b className="blueText">Aglaya </b>Software</h3>
                                    </div>

                                    <div className="our-mission">
                                        <p>We are a creative and innovative team, with experience in business situations.</p>
                                        <p>We offer integral technological solutions for your company, with versatile and accessible schemes that adapt to any company in any industry.</p>
                                        <br/>
                                        <h5>You imagine it, <span className="blueText">we develop it!</span></h5>
                                    </div>
                                    
                                </div>

                                <div className="col-lg-12">
                                    <div className="about-title">
                                        <h3>What<b className="blueText"> makes us different</b>?</h3>
                                        <p>We are a passionate team, with experience in different areas of the market, we have achieved to implement technological solutions to improve efficiency and provide added value to our customers in:</p>
                                    </div>

                                    <div className="why-choose-us">
                                        <div className="row m-0">
                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-Truck"></i>
                                                    <h5 className="blueText">Logistics</h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-Dollars"></i>
                                                    <h5 className="blueText">Finance</h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-WorldWide"></i>
                                                    <h5 className="blueText">Marketing</h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-FullShoppingCart"></i>
                                                    <h5 className="blueText">E-Commerce</h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-DesktopMonitor"></i>
                                                    <h5 className="blueText">Production</h5>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 p-0">
                                                <div className="single-choose">
                                                    <i className="icon icon-Chart"></i>
                                                    <h5 className="blueText">Business Intelligence</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="animation-box1"><img data-src="../img/cloud-computing.png" width="70px" alt="service" className="lazyload"/></div>
            <div className="animation-box2"><img data-src="../img/devices-blue.png" alt="service" className="lazyload"/></div>
            <div className="animation-box3"><img data-src="../img/1.png" alt="service" className="lazyload"/></div>
            <div className="animation-box6"><img data-src="../img/code-round.png" width="70px" alt="service" className="lazyload"/></div>
        </section>
        { /* End About Area */ }

        { /* Start Services Area */ }
        <section id="services" className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Our <b className="blueText">Services</b></h2>     
                    
                    <p className="mw-100">We focus on provide you the right tools to prepare you for your company's digital transformation.</p>
                    <p className="mw-100">We have professional team specialized in different areas to guarantee quality in your requirements. We work with the technologies and solutions that best integrate with your needs:</p>
                </div>                
                   
                <div className="row text-center justify-content-md-center">
                  <div className="col-12 m-b-20">
                    <h4><b className="blueText">Web</b> Design</h4>     
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-Web"></i>
                          <h3 className="blueText">Web Design</h3>
                          <p>The business card to your customers. We include web hosting, domain and maintenance.</p><br />
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-Pen"></i>
                          <h3 className="blueText">SEO & UX/UI Design</h3>
                          <p>We create sites that generate great value, focused on giving the best user experience.</p><br />
                      </div>
                  </div> 

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                    <div className="single-services">
                        <i className="icon icon-Eye"></i>
                        <h3 className="blueText">AR & VR</h3>
                        <p>Offer your customers new experiences to know your products and services.</p><br />
                    </div>
                  </div>

                  <div className="col-12 m-b-20 ">
                    <h4><b className="blueText">Custom</b> Development</h4>     
                  </div>
                  
                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-WorldWide"></i>
                          <h3 className="blueText">Web Development</h3>
                          <p>We build and manage Web Systems in multiple languages and frameworks according to your requirements.</p>
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                    <div className="single-services">
                        <i className="icon icon-DesktopMonitor"></i>
                        <h3 className="blueText">Desktop Development</h3>
                        <p>We design efficient and friendly solutions for desktop environments.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-Phone"></i>
                          <h3 className="blueText">Mobile Development</h3>
                          <p>We are specialists in cross-platform application development with vanguard technologies.</p>
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                    <div className="single-services">
                        <i className="icon icon-FullShoppingCart"></i>
                        <h3 className="blueText">E-Commerce</h3>
                        <p>Take your store to the next level, with our e-commerce solutions, friendly and intuitive.</p>
                    </div>
                  </div>

                  <div className="col-12 m-b-20 ">
                    <h4><b className="blueText">Smart</b> Assistants</h4>     
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-Microphone"></i>
                          <h3 className="blueText">Voice Platforms</h3>
                          <p>Amazon Alexa, most used smart assistant. Add it to your staff!</p>
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                      <div className="single-services">
                          <i className="icon icon-Message"></i>
                          <h3 className="blueText">Chatbots</h3>
                          <p>Boost your business with the implementation of Chatbots with the ability to interact with your customers.</p>
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 p-b-30">
                    <div className="single-services">
                        <i className="icon icon-Briefcase"></i>
                        <h3 className="blueText">Virtual Assistants</h3>
                        <p>Give your company a face with virtual assistants directly on your website.</p>
                    </div>
                  </div>                
              </div>
            </div>
        </section>
        { /* End Services Area */ }

        { /* Start Subscribe Area */ }
        <div className="subscribe-area">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <p><b>Guaranteed quality!</b> We work with agile teams focused on developing solutions to improve your customer's experience with strict quality controls at each phase of your project.</p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="partner-slides">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/nodejs.png" alt="tech"/>
                              </div>
                          </div>
                          
                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/python.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/django.png" alt="tech"/> 
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/postgresql.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/sqlserver.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/css3.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/bootstrap.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/os-ios.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/android.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/ionic.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/angular.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/aws.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/less.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/react.png" alt="tech"/> 
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/salesforce.png" alt="tech"/>
                              </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="item text-center">
                                  <img className="prog-icon owl-lazy" data-src="../img/sass.png" alt="tech"/>
                              </div>
                          </div>

                      </div>
                </div>
              </div>
          </div>
      </div>
      { /* End Subscribe Area */ }

        { /* Start Portfolio Area */ }
        <section id="portfolio" className="portfolio-area ptb-100 bg-f9f9f9">
            <div className="container">
                <div className="section-title">
                    <h2><b>Portfolio</b></h2>
                    <p>Some of our pr that have helped to improve the efficiency in the processes of our customers:</p>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-work">
                            <div className="work-image">
                                <img data-srcset="../img/portafolio/lawyer-400.jpg 400w,
                                ../img/portafolio/lawyer-600.jpg 600w,
                                ../img/portafolio/lawyer-800.jpg 800w,
                                ../img/portafolio/lawyer.jpg 1000w"

                                data-src="../img/portafolio/lawyer-600.jpg" 
                                
                                alt="Aglaya Software - Law Firm Tracking"
                                
                                className="lazyload"/>

                                <a href="../img/portafolio/lawyer.jpg" className="popup-btn" title="Law Firm Tracking"><i className="icon icon-Search"></i></a>
                            </div>
                            <h3>Law Firm Tracking</h3>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-work">
                            <div className="work-image">
                                <img data-srcset="../img/portafolio/dentist-software-400.jpg 400w,
                                ../img/portafolio/dentist-software-600.jpg 600w,
                                ../img/portafolio/dentist-software-800.jpg 800w,
                                ../img/portafolio/dentist-software.jpg 1000w"

                                data-src="../img/portafolio/dentist-software-600.jpg" 

                                alt="Aglaya Software - Patient Control"
                                
                                className="lazyload"/>

                                <a href="../img/portafolio/dentist-software.jpg" className="popup-btn" title="Patient Control"><i className="icon icon-Search"></i></a>
                            </div>
                            <h3>Patient Control</h3>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-work">
                            <div className="work-image">
                                <img data-srcset="../img/portafolio/service-desk-400.jpg 400w,
                                ../img/portafolio/service-desk-600.jpg 600w,
                                ../img/portafolio/service-desk-800.jpg 800w,
                                ../img/portafolio/service-desk.jpg 1000w"
                                
                                data-src="../img/portafolio/service-desk-600.jpg" 

                                alt="Aglaya Software - Service Desk"
                                
                                className="lazyload"/>

                                <a href="../img/portafolio/service-desk.jpg" className="popup-btn" title="Service Desk"><i className="icon icon-Search"></i></a>
                            </div>
                            <h3>Service Desk</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        { /* End Portfolio Area */ }

        { /* Start Feedback Area */ }
        { /* <section className="feedback-area ptb-100 bg-f9f9f9">
            <div className="container">
                <div className="section-title">
                    <h2>Our <b>Feadback</b></h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div className="row">
                    <div className="feedback-slides">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar1.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar2.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar3.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar1.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar2.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="img">
                                        <img src="../img/client-avatar3.jpg" alt="client">
                                    </div>
                                    <h4>Josh Buttler</h4>
                                    <span>Web Developer</span>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                <i className="icofont-quote-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */ }
        { /* End Feedback Area */ }

        { /* Start Blog Area */ }
        { /* <section id="blog" className="blog-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Our Latest <b>News</b></h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-blog-post">
                            <div className="post-thumbnail">
                                <a href="#"><img src="../img/blog-img1.jpg" alt="blog-img"></a>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">Risus commodo viverra maecenas accumsan lacus vel facilisis.</a></h3>
                                <a href="#" className="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-blog-post">
                            <div className="post-thumbnail">
                                <a href="#"><img src="../img/blog-img2.jpg" alt="blog-img"></a>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">Risus commodo viverra maecenas accumsan lacus vel facilisis.</a></h3>
                                <a href="#" className="read-more">Read More</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="single-blog-post">
                            <div className="post-thumbnail">
                                <a href="#"><img src="../img/blog-img3.jpg" alt="blog-img"></a>
                            </div>

                            <div className="post-content">
                                <h3><a href="#">Risus commodo viverra maecenas accumsan lacus vel facilisis.</a></h3>
                                <a href="#" className="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */ }
        { /* End Blog Area */ }

        { /* Start Work With Us Area */ }
        { /* <section className="work-with-us ptb-100">
            <div className="container">
                <h3>Want to work with us?</h3>
                <span>Lets talk about project</span>
                <a href="#" className="btn btn-primary">Get Started</a>
            </div>
            <div className="animation-box1"><img src="../img/3.png" alt="image"></div>
            <div className="animation-box2"><img src="../img/4.png" alt="image"></div>
            <div className="animation-box3"><img src="../img/1.png" alt="image"></div>
        </section> */ }
        { /* End Work With Us Area */ }       

        { /* Start Contact Area */ }
        <section id="contact" className="contact-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2><b>Contact Us</b></h2>
                    <p>Tell us about your project. We take care of making it happen!</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-box">
                            <div className="icon">
                                <i className="icofont-google-map"></i>
                            </div>
                            <h4>Address</h4>
                            { /* <p><a href="javascript: void(0)">Los Robles</a></p> */ }
                            <p><a href="#" onClick={ handleClick }>Managua, Nicaragua</a></p>
                            <p><a href="#" onClick={ handleClick }>Madrid, Spain</a></p>
                            <br/>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-box">
                            <div className="icon">
                                <i className="icofont-envelope"></i>
                            </div>
                            <h4>Email</h4>
                            <p><a href="#" onClick={ handleClick }>info@aglayasoftware.com</a></p>
                            <p><a href="#" onClick={ handleClick }></a></p>
                            <br/>
                            { /* <p><a href="javascript: void(0)">support@aglayasoftware.com</a></p> */ }
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="contact-box">
                            <div className="icon">
                                <i className="icofont-phone"></i>
                            </div>
                            <h4>Phone Numbers</h4>
                            <p><a href="#" onClick={ handleClick }>+(505) 5704 8835</a></p>
                            <p><a href="#" onClick={ handleClick }>+34 (640) 85 84 87</a></p>
                            <br/>
                            { /* <p><a href="javascript: void(0)">+505 5820 5929</a></p> */ }
                        </div>
                    </div>

                    { /* <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="get-in-touch">
                            <h3>Get in Touch</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <ul>
                                <li><a href="#" className="icofont-facebook"></a></li>
                                <li><a href="#" className="icofont-twitter"></a></li>
                                <li><a href="#" className="icofont-instagram"></a></li>
                                <li><a href="#" className="icofont-linkedin"></a></li>
                            </ul>
                        </div>
                    </div> */ }

                    { /* <div className="col-lg-8 col-md-12 col-sm-12">
                        <form id="contactForm">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="name" id="name" required data-error="Please enter your name" placeholder="Name">
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" id="email" required data-error="Please enter your email" placeholder="Email">
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <textarea name="message" className="form-control" id="message" cols="30" rows="4" required data-error="Write your message" placeholder="Message"></textarea>
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </form>        
                    </div> */ }
                </div>
            </div>
        </section>
         { /* End Contact Area */ }

        { /* Start Footer Area */ }
		<footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <p>Copyright &copy; 2020 Aglaya Software. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    { /* End Footer Area */ }
    
    <div className="go-top"><i className="icofont-stylish-up"></i></div>   
      
    </React.Fragment>
  );
}

export default EnglishIndex;
